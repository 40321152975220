// GLOBALES
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$theme-color: white;
$bimyou-color: #004159;
$second-theme-color: #2daef7;
$button-color: #C3009E;
$main-color: #5B5B75;

/*
"input_icon": 1,
*/
:root {
  --header_text_color: #565671;
  --header_icon_show: true;
  --header_bg_color: #ffffff;
  --header_icons_color: #565671;
  --messages_agent_bg_color: #D8D8DD;
  --messages_agent_text_color: #565671;
  --messages_agent_icon_show: false;
  --messages_user_bg_color: #ffffff;
  --messages_user_text_color: #565671;
  --messages_user_icon_show: false;
  --messages_content_bg_color: #F7F7F7;
  --artifacts_button_text_color: #FFFFFF;
  --artifacts_button_bg_color: #565671;
  --artifacts_button_hover_text_color: #565671;
  --artifacts_button_hover_bg_color: #88FAB8;
  --artifacts_button_disabled_text_color: #FFFFFF;
  --artifacts_button_disabled_bg_color: #808080;
  --artifacts_link_button_text_color: #FFFFFF;
  --artifacts_link_button_bg_color: #565671;
  --artifacts_link_button_hover_text_color: #565671;
  --artifacts_link_button_hover_bg_color: #88FAB8;
  --open_text_color: #565671;
  --open_bg_color: #FFFFFF;
  --open_icon_show: true;
  --open_shape: 50%;
  --input_text_color: #565671;
  --input_bg_color: #FFFFFF;
  --input_icon_color: white;
  --input_icon_bg_color: #565671;
  --input_clip_color: #FFFFFF;
  --input_clip_bg_color: #565671;
  --chat_bg_color: #FFFFFF;
}

body {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

// HEADER CONTAINER
.header {
  .header-container {
    z-index: 1010;
    height: 75px;
    background: var(--header_bg_color);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    display: grid;
    grid-template-columns: auto auto;

    .header-content {
      display: flex;
      column-gap: 10px;
      align-items: center;

      & img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: var(--header_icon_show);
      }

      >div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        span {
          font-weight: 400;
          color: var(--header_text_color);

          &:nth-child(1) {
            font-size: 17px;
            line-height: 1.2;
            margin-bottom: 1px;
          }

          &:nth-child(2) {
            font-size: 13px;
          }
        }
      }

    }


    .MuiSvgIcon-root,
    svg path {
      fill: var(--header_icons_color);
    }

    .btn-container {
      display: flex;
      gap: 8px;

      >div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.widget-container {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  font-size: 12px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--chat_bg_color);
  opacity: 1;

  input {
    color: var(--input_text_color);
    margin-right: 20px;
    margin-left: 10px;
    font-size: 14px;
    padding: 4px 0 1px;
    unicode-bidi: plaintext;

    &:disabled {
      color: #A6A6B9;
    }

    &::placeholder {
      color: var(--input_text_color);
      opacity: 1;
    }
  }

  .bim-warn {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 20px;
    width: 100%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    img {
      width: 150px;
      margin-bottom: 20px;
    }
  }

  &.normal {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100%;
    right: 0;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 5px 40px rgba(0, 0, 0, .16);
  }

  &:not(.visible):not(.visible_no_animation):not(.no_animation) {
    animation: widget-hide .5s backwards;
  }

  &.visible_no_animation {
    visibility: visible;
  }

  &.no_animation {
    visibility: hidden;
    opacity: 0;
  }

  &.visible {
    animation: widget-show .5s forwards;
  }
}

.chat-msg-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;

  img {
    margin: 5px 0;
    width: 100%;
  }

  .rating-message {
    text-align: center;
    opacity: 0.5;
  }

  .MuiCardContent-root {
    padding: 0px !important;
  }

  .chat-img {
    width: 200px;
    height: 200px;
    background-size: cover;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .MuiPaper-root {
    background-color: inherit;
  }

  .chat-media-container {
    video {
      width: 330px;
      border-radius: 5px;
    }

    a {
      text-decoration: inherit;
    }
  }

  .chat-msg,
  .chat-media {
    border-radius: 5px;
    margin: 4px 0;
    word-break: break-word;

    &.elements {
      max-width: 500px;
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
    }

    .MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }

  audio:hover,
  audio:focus,
  audio:active {
    outline: none
  }

  .chat-msg {
    padding: 5px 8px;
  }

  .chat-media {
    padding: 0;

    .chat-msg {
      margin: 0;
    }
  }

  &.visitor {
    align-items: flex-end;
    width: 100%;

    .comment__content__file_container {
      background-color: var(--messages_user_bg_color);
    }

    .comment__content__file_content>div:nth-child(2)>span {
      color: var(--messages_user_text_color);
    }

    .chat-msg {
      background-color: var(--messages_user_bg_color);
      border-radius: 0 10px 10px 10px;

      p {
        color: var(--messages_user_text_color);
        unicode-bidi: plaintext;
        margin: 0;
      }
    }
  }

  &.agent {
    align-items: flex-start;

    .comment__content__file_container {
      background-color: var(--messages_agent_bg_color);
    }

    .comment__content__file_content>div:nth-child(2)>span {
      color: var(--messages_agent_text_color);
    }

    .chat-msg {
      background-color: var(--messages_agent_bg_color);
      border-radius: 0 10px 10px 10px;
      box-sizing: border-box;

      &.locale_arabic {
        width: 100%;
      }

      &.detached {
        background: inherit;
        border-radius: 0;
        padding: 0;
        margin: 0;

        .MuiCardContent-root {
          p {
            margin: 0;
          }
        }
      }

      p {
        color: var(--messages_agent_text_color);
        unicode-bidi: plaintext;
        margin: 0;
      }
    }
  }

  &.sibling {

    .avatar {
      display: none;
    }
  }

  &:not(.sibling) {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 5px;
  }
}

.carousel {
  height: 225px;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;

  div:nth-child(4) {
    margin-top: 0px;
  }

  .CarouselItem {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 0 10px;

    >div {
      height: 200px;
    }
  }

  .carousel-item {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .carousel-media {
    background-color: white;
    height: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .carousel-media-caption {
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    padding: 8px;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    transition: 300ms;
    cursor: pointer;
    font-size: 16px;
    font-weight: 200;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      filter: brightness(115%);
    }

  }

}

// AVATAR


.avatar-container {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  padding-bottom: 1px;
  width: 50px;
  height: 100%;

  .time {
    color: #9D9D9D;
    top: 45px;
  }

  .visitor & {
    right: 0;
  }
}

.avatar {
  width: 38px;
  height: 38px;
  margin-bottom: 5px;
  border-radius: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .agent & {
    left: 10px;
    display: var(--messages_agent_icon_show);
  }

  .visitor & {
    right: 10px;
    display: var(--messages_user_icon_show);
  }
}

.visitor-avatar {
  display: block;
  margin: auto;
  height: 100%;
}

// IVR-POWERS

.call-rtc {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  .MuiCardContent-root {
    padding: 0px !important;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 10px;
    position: relative;
    max-width: 600px;
    margin: 0 auto;
  }

  #remote-name,
  #remote-resolution,
  #remote-bitrate {
    display: none;
  }

  .call-videos {
    // display: grid;
    // grid-template-columns: 100px auto;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    grid-column-gap: 5px;
    column-gap: 5px;
  }
}

#videolocal1,
#videoremote1,
#screenremote {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  max-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

#container-screenremote {
  display: flex;
  justify-content: center;
  align-items: center;
}

#localvideo,
#remotevideo>div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

#localvideo {
  position: absolute;
  width: 18%;
  left: 0px;
  bottom: 12px;
}

.rtc-buttons {
  width: 55px;
  height: 70px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;

  .rubberBand {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  #call {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #88FAB8;
  }

  #callOut {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #FF6B87;
  }
}

// MESSAGE LIST
.message-list-content {
  display: flex;
  height: 100%;
  background: var(--chat_bg_color);
  transition: all 0.5s linear;
  padding: 0 4px 4px 10px;
  position: relative;
  overflow: hidden;

  .message-list-container {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 15px 10px 10px 10px;
    border-radius: 15px 15px 0 15px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: var(--messages_content_bg_color) var(--messages_content_bg_color);
    scrollbar-width: thin;
    background-color: var(--messages_content_bg_color);

    &.pd_bottom {
      padding: 15px 10px 30px 10px;
    }

    .message-list-loading {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      span {
        font-size: 20px;
      }

      .MuiCircularProgress-root {
        color: #565671;
      }
    }
  }
}

// MESSAGE INFO
.message-list-info {
  bottom: 50px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  position: absolute;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    color: var(--messages_agent_bg_color);
    width: 100%;
    padding: 0 10px;
    height: 30px;

    .MuiCircularProgress-colorPrimary {
      color: var(--messages_agent_bg_color);
    }
  }
}

// ARTIFACTS
/* LIST MESSAGE */
.artifact_list_message__drawer_header,
.artifact_list_message__drawer_button {
  background-color: var(--artifacts_button_bg_color);
  color: var(--artifacts_button_text_color);
}

// BUTTON
button:focus {
  outline: 0;
}

.btn-primary {
  background-color: $main-color;
  color: #fff;
  border: 0;
  text-align: center;
  margin: 5px 0;
  border-radius: 5px;
  font-weight: 400;
  min-height: 35px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  transition: background-color 0.5s, color 0.5s;
  position: relative;

  &.button-link {
    background-color: var(--artifacts_link_button_bg_color);
    color: var(--artifacts_link_button_text_color);

    &:hover {
      background-color: var(--artifacts_link_button_hover_bg_color);
      color: var(--artifacts_link_button_hover_text_color);
    }

    padding: 5px 30px;
  }

  &.button-reply {
    background-color: var(--artifacts_button_bg_color);
    color: var(--artifacts_button_text_color);
    padding: 0;
    height: 35px;
    min-height: 30px;
    margin: 0 5px;
    width: auto;
    font-size: 13px;

    &:hover {
      background-color: var(--artifacts_button_hover_bg_color);
      color: var(--artifacts_button_hover_text_color);
    }

    &:disabled {
      background-color: var(--artifacts_button_disabled_bg_color);
      color: var(--artifacts_button_disabled_text_color);
    }
  }

  &.button {
    background-color: var(--artifacts_button_bg_color);
    color: var(--artifacts_button_text_color);

    &:disabled {
      background-color: var(--artifacts_button_disabled_bg_color);
      color: var(--artifacts_button_disabled_text_color);
    }

    &:hover {
      background-color: var(--artifacts_button_hover_bg_color);
      color: var(--artifacts_button_hover_text_color);
    }

    padding: 5px 30px;
  }

  &.button-whatsapp {
    background-color: #25D366;

    &:hover {
      background-color: #25D366;
      color: white;
    }
  }

  .button-new {
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35px;

    svg {
      width: 16px;
    }
  }

  &:hover {
    color: $main-color;
    background-color: #88FAB8;
  }

  .MuiCircularProgress-colorPrimary {
    color: white;
  }

  &:disabled,
  &:disabled:hover {
    background-color: grey;
    pointer-events: none;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
}

// CHAT BUTTON
.chat-container {
  width: 82px;
  height: 82px;
  display: grid;
  align-items: center;
  border-radius: var(--open_shape);
  background: var(--open_bg_color);
  visibility: visible;
  box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.2), 0px 3px 11px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  bottom: 5px;
  margin: 20px 30px;
  z-index: 1;
  transition: none !important;
  opacity: 1;
  cursor: pointer;
  overflow: hidden;

  &.right {
    right: 0;
    grid-template-columns: 200px 82px;
    overflow: hidden;

    .chat-button {
      position: inherit;
      right: 0px;
    }
  }

  &.left {
    left: 0;
    grid-template-columns: 82px 200px;

    .chat-agent {
      grid-template-columns: auto 40px;
      padding: 0 15px 0 10px;
    }

    .chat-button {
      left: 0;
      position: initial;
    }
  }

  &.button_expand {
    width: 282px;
    transition: width 0.5s linear;
  }

  &.visible {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .chat-agent {
    display: grid;
    align-items: center;
    column-gap: 10px;
    padding: 0 10px 0 15px;
    text-align: center;

    span {
      font-size: 13px;
      color: var(--open_text_color);
    }

    img {
      width: 40px;
      border-radius: 50%;
    }
  }

  .chat-button {
    margin: 0;
    bottom: 0px;
    border-radius: 50px;
    background: var(--open_bg_color);
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;

    &.full_width {
      img {
        width: 100%;
        height: 100%;
      }
    }

    & img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      display: var(--open_icon_show);
    }

  }
}

// CHAT RATING DIALOG
#chat-rating {

  .MuiDialogTitle-root {
    padding: 13px 15px 13px 15px;
    color: white;
    text-align: center;

    .MuiTypography-h6 {
      font-size: 16px;
    }
  }

  .MuiDialogContent-root,
  .MuiDialogActions-root {
    background-color: white;
  }

  .rating_buttons {
    display: grid;
    justify-content: center;
    grid-template-columns: 50px 50px;
    column-gap: 10px;
    padding-bottom: 10px;

    div {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      transition: all 0.3s linear;

      &.rating_good {
        background: rgb(76, 175, 80, 0.3);
        border: 2px solid #4CAF50;

        path {
          fill: #4CAF50;
        }

        &.selected {
          background: #4CAF50;
          border: inherit;

          path {
            fill: white;
          }
        }
      }

      &.rating_bad {
        background: rgba(245, 0, 87, 0.3);
        border: 2px solid #F50057;

        path {
          fill: #F50057;
        }

        &.selected {
          background: #F50057;
          border: inherit;

          path {
            fill: white;
          }
        }
      }

      &.selected {
        opacity: 1;
      }
    }
  }

  .MuiTextField-root {
    label {
      color: $main-color;
    }
  }

  .MuiDialog-paper {
    border-radius: 20px 20px 0 20px;
    min-width: 330px;
    background-color: $main-color;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  textarea {
    color: $main-color;
    font-size: 0.8em;

    &::placeholder {
      color: $main-color;
    }
  }

  .MuiDialogActions-root {
    .MuiButton-label {
      font-size: 13px;
    }

    #skip_rating_button {
      color: #f50057;

      &:hover {
        background-color: rgba(245, 0, 87, 0.1);
      }
    }

    #send_rating_button {
      color: #4CAF50;

      &:hover {
        background-color: rgb(76, 175, 80, 0.1);
      }
    }
  }

}

// INPUT FORM
.powered {
  position: absolute;
  width: 100px;
  bottom: 5px;
  right: 10px;
  z-index: 0;
}

.form-container {
  width: 100%;
  padding: 10px 8px 10px 15px;
  background-color: var(--input_bg_color);
  bottom: 10px;
  height: 50px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;

  &.too_much_messages {
    justify-content: left;

    .MuiAlert-message {
      font-size: 10px;
    }
  }

  .input_offline {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--input_text_color);
    padding-left: 8px;
  }

  .form-upload-file {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    background-color: var(--input_clip_bg_color);

    label,
    span {
      background: inherit;
      box-shadow: initial;
      padding: 0;
      width: 22px;
      min-width: 22px;
      height: 100%;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    label {
      svg {
        width: 13px;
        height: 25px;
        cursor: pointer;

        path {
          fill: var(--input_clip_color);
        }
      }
    }

    span {
      svg {
        color: var(--input_clip_color);
      }
    }
  }

  .counter-lng {
    position: absolute;
    bottom: 7px;
    font-size: 9px;
    color: rgba(0, 0, 0, 0.54);

    &.error {
      color: #FF2F56;
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInput-underline:before {
      content: none;
    }

    .MuiInput-underline:after {
      border-bottom: 1px solid var(--input_text_color);
    }
  }

  .send-button {
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    background-color: var(--input_icon_bg_color);

    &.disabled {
      pointer-events: none;
      background-color: #E0E0E0;
    }

    &.svg-1,
    &.svg-4,
    &.svg-5 {
      path {
        fill: var(--input_icon_color);
        stroke: transparent;
      }
    }

    &.svg-2,
    &.svg-3 {
      path {
        stroke: var(--input_icon_color);
      }
    }

    svg {
      width: 17px;
      height: 17px;
    }
  }
}

// Animation
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 15px;
  transform: scale(0.3);
}

.lds-ellipsis div {
  position: absolute;
  top: 17px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--input_text_color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

// WARNING

.widget-container .bim-flx {
  display: flex;
}

// SCROLLBAR

::-webkit-scrollbar-track {
  background: var(--messages_content_bg_color);
}

::-webkit-scrollbar {
  width: 4px;
  background: var(--messages_content_bg_color);
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
}

// ANIMATIONS


@keyframes widget-show {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes widget-hide {
  0% {
    //   transform: translateY(0);
  }

  99% {
    opacity: 0;
    //  transform: translateY(50%);
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}


@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
  animation-duration: 1s;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-130%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 0.5s;
  z-index: 5;
}

#mobile {

  .header-container {
    height: 60px;
  }

  .chat-container {
    width: 60px;
    height: 60px;
    margin: 10px 20px;

    &.right {
      grid-template-columns: 160px 60px;

      .chat-agent {
        padding: 0 0px 0 15px;
      }
    }

    &.left {
      grid-template-columns: 60px 160px;

      .chat-agent {
        padding: 0 15px 0 0;
      }
    }

    &.button_expand {
      width: 220px;
    }

    .chat-agent {

      img {
        width: 35px;
      }

      span {
        font-size: 10px;
        color: var(--open_text_color);
      }
    }

    .chat-button {
      width: 60px;
      height: 60px;

      &.full_width {
        img {
          width: 100%;
          height: 100%;
        }
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
.centribal_typing {
  width: 60px;
  display: flex;
  justify-content: center;
  padding: 10px 0;

  &_container {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--messages_agent_bg_color);
    color: var(--messages_agent_bg_color);
    animation: typing 1s infinite linear;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--messages_agent_bg_color);
      color: var(--messages_agent_bg_color);
      animation: typing_before 1s infinite linear;
    }

    &::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--messages_agent_bg_color);
      color: var(--messages_agent_bg_color);
      animation: typing_after 1s infinite linear;
    }
  }
}

@keyframes typing_before {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes typing {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes typing_after {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}