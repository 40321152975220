.zendesk_guide {
  height: calc(100% - 50px - 75px);
  display: flex;
  transition: all 0.5s linear;
  padding: 0 10px 4px 10px;
  position: relative;
  background: #FFF;

  &_container {
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 15px 15px 0 15px;
    overflow: hidden;
    scrollbar-color: var(--messages_content_bg_color) var(--messages_content_bg_color);
    scrollbar-width: thin;
    background-color: #FFFFFF;
    position: relative;
    padding-bottom: 5px;

    &_loading {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      svg {
        color: #565671;
      }
    }

    &_feedback {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;

      span {
        font-size: 14px;
        font-weight: bold;
        color: #9E9E9E;
      }
    }
  }

  &_buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    bottom: -15px;
    left: 0;
    width: 100%;
    margin: 0 auto;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #FABF3A;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    &_return {
      height: 30px;
      width: 30px;

      svg {
        path {
          stroke: #FFFFFF;
        }
      }
    }

    &_close {
      height: 32px;
      width: 32px;

      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }

    &_none {
      width: 30px;
      height: 0;
    }
  }

  &_articles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    color: #565671;
    height: 100%;
    width: 100%;
    padding: 0 0 10px 0;

    &_count {
      padding: 0 10px;
    }

    &_elements {
      display: grid;
      gap: 8px;
      overflow: auto;
    }

    .MuiPagination-root {
      max-width: 300px;
      margin: 0 auto;
    }

    .MuiPaginationItem-rounded {
      border-radius: 50%;
    }

    .MuiPaginationItem-root {
      height: 25px;
      width: 25px;
      min-width: 25px;
      font-size: 14px;
    }

    .MuiPaginationItem-page.Mui-selected {
      background: #565671;
      color: white;
    }

    &_element {
      cursor: pointer;
      display: grid;
      gap: 8px;
      padding: 0 10px;

      &:hover {
        .element {
          &_title {
            text-decoration: underline;
          }
        }
      }

      .element {
        &_title {
          font-size: 13px;
          font-weight: bold;
          color: #000;
        }

        &_body {
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: 11px;
        }

        &_date {
          font-size: 10px;
        }
      }
    }

    .MuiDivider-root {
      background-color: #FABF3A;
      margin: 0 10px;
    }
  }

  &_article {
    display: flex;
    flex-direction: column;
    color: #565671;
    height: 100%;
    overflow: auto;
    padding: 6px;
    gap: 8px;

    .element_date {
      font-size: 10px;
      color: rgba(0,0,0,0.5);
    }

    .element_title {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }

    .element_body {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 0;

      iframe, img {
        width: -webkit-fill-available !important;
        width: -moz-available !important;
        height: auto !important;
      }

      a {
        color: #565671;
        font-weight: bold;
      }

      * {
        margin: 0;
      }
    }
  }
}
