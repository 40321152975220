.lightbox {
  &_slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    overflow: hidden;
    transition: all 0.3s linear;
    max-width: 366px;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_content {
      display: flex;
      width: 100%;
      overflow-x: hidden;
      height: 100%;
      gap: 10px;
      padding: 8px 10px;
      scroll-behavior: smooth;
    }

    &_placeholder {
      position: relative;
      box-sizing: border-box;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      width: 90px;
      height: 90px;
    }

    &_item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      border-radius: 5px;
      min-width: 140px;
      height: 215px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
        background: white;

        > div {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
          border-radius: 5px;
          position: relative;
        }
      }
    }

    &_divider {
      min-width: 65px;
    }

    &_action {
      width: 20px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.hidden_action {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      }

      &.is_disabled {
        pointer-events: none;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .lightbox_slider_content {
    overflow: scroll;
  }
}