#root {
  .artifact_postback {
    display: grid;
    border-radius: 5px;

    &__body {
      display: grid;
      padding: 5px;

      span {
        &:nth-child(1) {
          color: var(--messages_agent_text_color);
          unicode-bidi: plaintext;
        }

        &:nth-child(2) {
          font-size: 13px;
          opacity: 0.7;
        }
      }
    }

    &__buttons {
      display: grid;
      gap: 2px;
      margin-bottom: 5px;
      margin-top: 5px;

      .button-postback {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}
