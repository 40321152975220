#root {
  .artifact_reply_button {
    display: grid;
    border-radius: 5px;

    &__header {
      img, video {
        width: 100%;
        margin: 0;
        border-radius: 5px 5px 0 0;
      }

      strong {
        margin-left: 5px;
      }

      .document_type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-radius: 5px;
        cursor: pointer;
        color: #565671;
        background: #F5F6F6;
        margin: 5px;
        padding-left: 20px;
        transition: all 0.2s linear;

        &:hover {
          box-shadow: 0 0 4px -2px rgba(0,0,0,0.5);
        }

        span {
          &:nth-child(2) {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #565671;
            border-radius: 50%;
            opacity: 0.5;
            margin-right: 10px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

    }

    &__body {
      display: grid;
      gap: 3px;
      padding: 5px;

      span {
        &:nth-child(1) {
          color: var(--messages_agent_text_color);
          unicode-bidi: plaintext;
        }

        &:nth-child(2) {
          font-size: 13px;
          opacity: 0.7;
        }
      }
    }

    &__buttons {
      display: grid;
      gap: 2px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
}
