#root {
  .artifact_reply {
    display: grid;
    border-radius: 5px;

    &__body {
      display: grid;
      padding: 5px;

      span {
        &:nth-child(1) {
          color: var(--messages_agent_text_color);
          unicode-bidi: plaintext;
        }

        &:nth-child(2) {
          font-size: 13px;
          opacity: 0.7;
        }
      }
    }

    &__buttons {
      display: grid;
      gap: 2px;
      margin-bottom: 5px;
      margin-top: 5px;

      .button-reply {
        margin: 0;
        font-size: 12px;

        img {
          max-width: 25px;
          margin: 0;
          margin-right: 5px;
        }
      }
    }

    &__detached {
      max-width: 366px;

      .artifact_reply__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        padding: 0;
        gap: 5px;

        .button-reply {
          display: flex;
          flex: 1 0 auto;
          max-width: 100%;
          text-align: center;
          padding: 5px;
          height: auto;
          border-radius: 15px;
        }

        .button-icon {
          width: 100%;
          background-position: center;
          background-size: cover;
          cursor: pointer;
          border-radius: 50%;
          background-repeat: no-repeat;
          box-shadow: rgba(0, 0, 0, 0.24) 0  2px 1px;
        }

        &.icons {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
        }
      }
    }
  }
}
