.comment {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__content {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;


    &__file {
      display: flex;
      flex-direction: column;

      > span {
        padding: 5px 10px;
        font-size: 14px;
        background: #f4f4f4;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 0 0 5px 5px;
      }

      &_container {
        display: grid;
        grid-template-columns: auto 40px;
        padding: 2px;
        border-radius: 5px 5px 0 0;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
      }

      &_content {
        display: grid;
        grid-template-columns: 50px auto;
        gap: 2px;
        font-size: 12px;

        > div {
          display: flex;
          align-items: center;
          overflow: hidden;

          > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        > span {
          align-items: center;
          display: flex;
        }
      }

      &_svg {
        position: relative;
        width: 40px;
        height: 40px;

        svg {
          width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          top: 12px;
          color: white;
          width: 50px;
          text-align: center;
          left: -5px;
        }
      }

      &_name {
        display: flex;
        align-items: center;
        overflow: hidden;

        > span {
          &:nth-child(1) {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      &_download {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        background: white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}