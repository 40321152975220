#root {
  .artifact_list_message {
    display: grid;
    border-radius: 5px;

    &__header {
      display: grid;

      span {
        unicode-bidi: plaintext;

        &:nth-child(1) {
          font-size: 15px;
          font-weight: bold;
        }

        &:nth-child(2) {
          font-size: 14px;
          opacity: 0.8;
          font-weight: 500;
        }

        &:nth-child(3) {
          font-size: 12px;
        }
      }
    }

    &__buttons {
      height: 40px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #5656;
      margin-top: 5px;
      align-items: center;
      cursor: pointer;

      button {
        pointer-events: none;
        padding: 5px;
      }

      span {
        font-weight: 500;
      }
    }

  }
}

.artifact_list_message__drawer {

  .MuiPaper-root {
    border-radius: 20px 20px 0 0;
    padding-bottom: 50px;
    overflow: hidden;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;

    svg path {
      fill: white !important;
    }
  }

  &_body {
    padding-bottom: 20px;
    overflow: auto;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  &_sections {
    display: grid;
    gap: 10px;

    > span {
      margin-top: 20px;
      margin-left: 20px;
      font-size: 18px;
      text-transform: uppercase;
      height: 35px;
      align-items: center;
      display: flex;
      font-weight: 400;
    }
  }

  &_rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 40px;

    > div {
      display: grid;
      span {
        &:nth-child(1) {
          font-size: 16px;
          color: var(--messages_agent_text_color);
          unicode-bidi: plaintext;
        }

        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }

  &_button {
    position: fixed;
    width: 100%;
    bottom: -50px;
    height: 50px;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;

    &.show {
      bottom: 0;
    }

    > div {
      border-radius: 50%;
      background: white;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}